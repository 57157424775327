import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WealthService } from './wealth.service';

@Injectable({
  providedIn: 'root',
})
export class WealthDataHelperService {
  constructor(private wealthService: WealthService) {}

  /**
   * Transforms a date from 'DD.MM.YYYY' format to 'YYYY-MM-DD' format.
   * @param dateString Date as a string (e.g., "31.12.2020")
   * @returns Date as a string in "YYYY-MM-DD" format, or null if no date is provided.
   */
  public reverseFormatDate(dateString: string): string {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split('.');
    if (parts.length !== 3) {
      return dateString;
    }
    const day = parts[0].padStart(2, '0');
    const month = parts[1].padStart(2, '0');
    const year = parts[2];
    return `${year}-${month}-${day}`;
  }

  public formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  /**
   * Transforms the form data for a category component into the desired format.
   * @param data The raw form data
   * @returns The transformed data object
   */
  public flattenRealEstateFormData(data: any): any {
    return {
      name: data.name,
      streetNameAndNumber: data.streetNameAndNumber,
      zipCode: data.zipCode,
      cityName: data.cityName,
      countryName: data.countryName,
      purchaseDate: this.reverseFormatDate(data.purchaseDate),
      purchasePrice: Number(data.purchasePrice) || null,
      propertySize: Number(data?.propertySize) || null,
      livingSpace: Number(data?.livingSpace) || null,
      constructionYear: Number(data?.constructionYear) || null,
      estimateCurrentValue: Number(data.estimateCurrentValue) || null,
      note: data?.note,
      categoryId: Number(data.categoryId),
      numberOfImageToAdd: data.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: [data?.imageToDelete || 0],
    };
  }

  public flattenRealEstateDetailsFormData(data: any) {
    return {
      name: data.name,
      streetNameAndNumber: data.streetNameAndNumber,
      zipCode: data.zipCode,
      cityName: data.cityName,
      countryName: data.countryName,
      purchaseDate: this.reverseFormatDate(data.purchaseInfo.purchaseDate),
      purchasePrice: data.purchaseInfo?.purchasePrice,
      propertySize: data.propertySize,
      livingSpace: data.livingSpace,
      constructionYear: data.constructionYear,
      estimateCurrentValue: data.headerInfo?.estimateCurrentValue,
      note: data.note,
      categoryId: data.categoryId,
      numberOfImageToAdd: data.headerInfo?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: data.headerInfo?.addImages?.imageToDelete || 0,
    };
  }

  public flattenArtFormData(data: any) {
    return {
      label: data.label,
      description: data.description,
      categoryId: Number(data.categoryId),
      note: data.note,
      numberOfImageToAdd: data.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: [data?.imageToDelete || 0],
      currentMarketValue: Number(data.currentMarketValue) || null,
      purchaseDate: this.reverseFormatDate(data.purchaseDate),
      purchasePrice: Number(data.purchasePrice) || null,
    };
  }

  public flattenArtDetailsFormData(data: any): any {
    return {
      label: data.label,
      description: data.description,
      purchasePrice: data.purchaseInfo.purchasePrice,
      purchaseDate: this.reverseFormatDate(data.purchaseInfo.purchaseDate),
      currentMarketValue: data.headerInfo.currentMarketValue,
      note: data.note,
      categoryId: data.categoryId,
      numberOfImageToAdd: data.headerInfo?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: data.headerInfo?.addImages?.imageToDelete || 0,
    };
  }

  public flattenOtherFormData(data: any): any {
    return {
      label: data.label,
      description: data.description,
      categoryId: 6,
      note: data.note,
      numberOfImageToAdd: data.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: [data?.imageToDelete || 0],
      currentMarketValue: Number(data.currentMarketValue) || null,
      purchaseDate: this.reverseFormatDate(data.purchaseDate),
      purchasePrice: Number(data.purchasePrice) || null,
    };
  }

  public flattenOtherDetailsFormData(data: any) {
    return {
      label: data.label,
      description: data.description,
      categoryId: data.categoryId,
      note: data.note,
      numberOfImageToAdd: data.headerInfo?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: data.headerInfo?.addImages?.imageToDelete || 0,
      currentMarketValue: data.headerInfo.currentMarketValue,
      purchaseDate: this.reverseFormatDate(data.purchaseInfo.purchaseDate),
      purchasePrice: data.purchaseInfo.purchasePrice,
    };
  }

  public flattenMobilityFormData(data: any, purchaseOptions: string) {
    return {
      name: data.name,
      manufacturerName: data.manufacturerName,
      model: data.model,
      firstRegistrationDate: this.reverseFormatDate(data.firstRegistrationDate),
      mileage: data.mileage,
      description: data?.description,
      licensePlateNo: data.licensePlatePart1 + '-' + data.licensePlatePart2 + '-' + data.licensePlatePart3,
      note: data.note,
      categoryId: Number(data.categoryId),
      numberOfImageToAdd: data.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: [data?.imageToDelete || 0],
      purchaseOption: {
        id: data.purchaseInfo.id,
        purchaseType: purchaseOptions,
        purchasePrice: data.purchaseInfo.purchasePrice,
        dateOfPurchase: this.reverseFormatDate(data.purchaseInfo.dateOfPurchase),
        currentMarketValue: data.purchaseInfo.currentMarketValue,
        financingEndDate: this.reverseFormatDate(data.purchaseInfo.financingEndDate),
        financingInstalment: data.purchaseInfo.financingInstalment,
        financingStartDate: this.reverseFormatDate(data.purchaseInfo.financingStartDate),
        leasingEndDate: this.reverseFormatDate(data.purchaseInfo.leasingEndDate),
        leasingInstalment: data.purchaseInfo.leasingInstalment,
        leasingStartDate: this.reverseFormatDate(data.purchaseInfo.leasingStartDate),
      },
    };
  }

  public flattenMobilityDetailsFormData(data: any): any {
    return {
      name: data.name,
      manufacturerName: data.manufacturerName,
      model: data.model,
      firstRegistrationDate: this.reverseFormatDate(data.firstRegistrationDate),
      mileage: data.mileage,
      description: data?.description,
      licensePlateNo: data.licensePlatePart1 + '-' + data.licensePlatePart2 + '-' + data.licensePlatePart3,
      note: data.note,
      categoryId: data.categoryId,
      numberOfImageToAdd: data.headerInfo?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: data.headerInfo?.addImages?.imageToDelete || 0,
      purchaseOption: {
        purchaseType: data.purchaseInfo.purchaseType,
        purchasePrice: data.purchaseInfo.purchasePrice,
        dateOfPurchase: this.reverseFormatDate(data.purchaseInfo.dateOfPurchase),
        currentMarketValue: data.headerInfo.currentMarketValue,
        financingEndDate: this.reverseFormatDate(data.purchaseInfo.financingEndDate),
        financingInstalment: data.purchaseInfo.financingInstalment,
        financingStartDate: this.reverseFormatDate(data.purchaseInfo.financingStartDate),
        leasingEndDate: this.reverseFormatDate(data.purchaseInfo.leasingEndDate),
        leasingInstalment: data.purchaseInfo.leasingInstalment,
        leasingStartDate: this.reverseFormatDate(data.purchaseInfo.leasingStartDate),
      },
    };
  }

  public flattenPreciousMetalFormData(data: any): any {
    return {
      weight: data.weight,
      details: data.details,
      purchaseDate: this.reverseFormatDate(data.purchaseDate),
      purchasePrice: data.purchasePrice,
      currentMarketValue: data.currentMarketValue,
      note: data.note,
      numberOfImageToAdd: data.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: [data?.imageToDelete || 0],
      categoryId: Number(data.categoryId),
    };
  }

  public flattenPreciousMetalDetailsFormData(data: any): any {
    return {
      weight: data.weight,
      details: data.details,
      purchaseDate: this.reverseFormatDate(data.purchaseInfo.purchaseDate),
      purchasePrice: data.purchaseInfo.purchasePrice,
      currentMarketValue: data.headerInfo.currentMarketValue,
      note: data.note,
      numberOfImageToAdd: data.headerInfo?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: data.headerInfo?.addImages?.imageToDelete || 0,
      categoryId: data.categoryId,
    };
  }

  public flattenJewelryFormData(data: any) {
    return {
      label: data.label,
      note: data.note,
      categoryId: Number(data.categoryId),
      numberOfImageToAdd: data?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: [data?.imageToDelete || 0],
      purchaseOption: {
        purchaseType: data.purchaseType,
        purchasePrice: data.purchaseInfo.purchasePrice,
        dateOfPurchase: this.reverseFormatDate(data.purchaseInfo.dateOfPurchase),
        currentMarketValue: data.purchaseInfo.currentMarketValue,
        financingEndDate: this.reverseFormatDate(data.purchaseInfo.financingEndDate),
        financingInstalment: data.purchaseInfo.financingInstalment,
        financingStartDate: this.reverseFormatDate(data.purchaseInfo.financingStartDate),
      },
    };
  }

  public flattenJewelryDetailsFormData(data: any): any {
    return {
      label: data.label,
      note: data.note,
      categoryId: data.categoryId,
      numberOfImageToAdd: data.headerInfo?.addImages?.numberOfImageToAdd?.length || 0,
      imageToDelete: data.headerInfo?.addImages?.imageToDelete || 0,
      purchaseOption: {
        purchaseType: data.purchaseInfo.purchaseType,
        purchasePrice: data.purchaseInfo.purchasePrice,
        dateOfPurchase: this.reverseFormatDate(data.purchaseInfo.dateOfPurchase),
        currentMarketValue: data.headerInfo.currentMarketValue,
        financingEndDate: this.reverseFormatDate(data.purchaseInfo.financingEndDate),
        financingInstalment: data.purchaseInfo.financingInstalment,
        financingStartDate: this.reverseFormatDate(data.purchaseInfo.financingStartDate),
      },
    };
  }

  /**
   * Performs the image upload using the provided URLs.
   * @param uploadUrls Array of upload URLs provided by the backend
   * @param images Array of image data, each containing a file and additional info
   * @param id ID of the record to which the images should be attached
   * @param basePath Base path required for the upload
   * @returns An Observable representing the combined upload process.
   */
  public uploadImages(
    uploadUrls: string[],
    images: { filesize: number; file: File }[],
    id: number,
    basePath: string,
  ): Observable<any> {
    const uploadObservables = images.map((image, index: number) => {
      return this.wealthService.uploadImageToEntityUrl(uploadUrls[index], image.file, id, basePath).pipe(
        catchError((error) => {
          console.error('❌ Fehler beim Hochladen des Bildes:', error);
          return throwError(error);
        }),
      );
    });
    return forkJoin(uploadObservables);
  }
}
