import { ClientSettings } from '../auth/client-settings';
import { BrokerMandateApprovalModel } from '../broker-mandate-approval.model';

export class ClientDetailResponse {
  client: ClientDetails;
  newContractMessages: number;
  consentApproved: boolean;
  personBankAccounts: PersonBankAccountModel[];
}

export class PersonBankAccountModel {
  iban: string;
  bic: string;
  bankName: string;
}

export class PoolStatusResponse {
  status: string;
}

export class ClientDetails {
  id: number;
  uid: string;
  uuid: string;
  email: string;
  emailVerified: boolean;
  firstname: string;
  lastname: string;
  token: string;
  profilePictureUrl: string | null;
  clientSettings: ClientSettings;
  street: string;
  houseNumber: string;
  zip: number;
  city: string;
  mobile: string;
  selected?: boolean;
  brokerMandateApproval: BrokerMandateApprovalModel;
  isEnabled?: boolean;
  isPoolClient?: boolean;
  hasPoolMandate?: boolean;
  poolCrmLink: string;
  birthday: Date | string;
  age: number;
  taxId: string;
  activeConversation: boolean;
  advisorId: number;
}

export class InviteEventData {
  client: ClientDetails;
  personalInvite: boolean;
  personalText: string;
}

export class PersonDocumentModel {
  assetData: AssetDataModel[];
  side: string;
}

export class AssetDataModel {
  id: number;
  mainAsset: MainAssetModel;
  attachments: PersonAttachmentModel;
  error: boolean;
  pending: boolean;
}

export class MainAssetModel {
  contentType: string;
  downloadUrl: string;
  filename: string;
  filesize: number;
  height: number;
  id: string;
  type: string;
  width: number;
}

export class PersonAttachmentModel {
  thumbnailSmall: any;
}

export class PersonDocumentUpload {
  documentType: 'DRIVER_LICENSE' | 'USER_ID' | 'PASSPORT';
  side: 'FRONT' | 'BACK';
  numberOfAttachmentsToAdd: number;
}
