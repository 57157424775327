import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { InsignConnectionRequestModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class InsignService {
  constructor(private http: HttpClient) {}

  public startInsignConnection(advisorUid: string, body: InsignConnectionRequestModel): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('clientId', body.clientId)
      .set('clientFirstName', body.clientFirstName)
      .set('clientLastName', body.clientLastName);

    return this.http.get<any>(`${environment.apiEndpoint}/insign/document/${advisorUid}`, { params });
  }

  public getInsignDocuments(advisorUid: string): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/insign/document/${advisorUid}`);
  }
}
