import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

export interface ImageToAdd {
  filesize: number;
  file: File;
}

export interface ImageObject {
  url: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class WealthService {
  private apiEndpoint = `${environment.apiEndpoint}/wealth`;
  private userId: string;
  private maxFiles = 20;
  private maxFileSize = 10 * 1024 * 1024; // 5MB in Bytes

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
  ) {}

  public getAggregatedAssets(uuid): Observable<any> {
    return this.http.get<any>(`${this.apiEndpoint}/aggregated-assets/${uuid}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        console.error('Error fetching aggregated assets:', error);
        return throwError(() => new Error('Failed to load aggregated assets'));
      }),
    );
  }

  public saveWealthData(category: string, uuid: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.apiEndpoint}/${category}/user/${uuid}`, data).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error saving wealth data:', error);
        return throwError(() => new Error('Failed to save wealth data'));
      }),
    );
  }

  public deleteAssetById(category: string, assetId: string, uuid: string): Observable<boolean> {
    const confirmationModalRef = this.modalService.show(ConfirmationModalComponent, {});
    confirmationModalRef.content.confirmationTitle = 'Bestätigung';
    confirmationModalRef.content.confirmationMessage =
      'Bist du dir sicher, dass du diesen Vermögensgegenstand löschen möchtest?';
    confirmationModalRef.content.danger = true;

    return new Observable<boolean>((observer) => {
      confirmationModalRef.content.action
        .pipe(
          tap((value: boolean) => {
            if (value === true) {
              this.http
                .delete<any>(`${this.apiEndpoint}/${category}/${assetId}/user/${uuid}`)
                .pipe(
                  map((res: any) => res),
                  catchError((error) => {
                    console.error(
                      `Error deleting asset. Category: ${category}, Asset ID: ${assetId}, UUID: ${uuid}.
                      HTTP Status: ${error.status}, Message: ${error.message}`,
                      error,
                    );
                    return throwError(() => new Error('Failed to delete asset'));
                  }),
                )
                .subscribe({
                  next: (response) => {
                    confirmationModalRef.hide();
                    observer.next(true);
                    observer.complete();
                  },
                  error: (err) => {
                    confirmationModalRef.hide();
                    observer.error(err);
                  },
                });
            } else {
              confirmationModalRef.hide();
              observer.next(false);
              observer.complete();
            }
          }),
        )
        .subscribe();
    });
  }

  public fetchAssetDetailsById(category: string, assetId: string, uuid: string): Observable<any> {
    this.userId = uuid;
    return this.http.get<any>(`${this.apiEndpoint}/${category}/${assetId}/user/${uuid}`).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error fetching asset details:', error);
        return throwError(() => new Error('Failed to load asset details'));
      }),
    );
  }

  public updateAssetDetailsById(category: string, assetId: string, uuid: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.apiEndpoint}/${category}/${assetId}/user/${uuid}`, data).pipe(
      map((res: any) => res),
      catchError((error) => {
        console.error('Error updating asset details:', error);
        return throwError(() => new Error('Failed to update asset details'));
      }),
    );
  }

  public onFileSelected(event: any, formControl: AbstractControl, imagesArray: string[] | ImageObject[]): void {
    const files: FileList = event.target.files ? event.target.files : event.dataTransfer.files;
    const newImages: ImageToAdd[] = [];
    let totalFiles = formControl.value ? formControl.value.length : 0;

    Array.from(files).forEach((file: File): void => {
      if (totalFiles >= this.maxFiles) {
        alert(`Maximale Anzahl von ${this.maxFiles} Bildern erreicht.`);
        return;
      }

      if (file.size > this.maxFileSize) {
        alert(`Datei "${file.name}" ist größer als 5MB und wird übersprungen.`);
        return;
      }

      const reader = new FileReader();

      reader.onload = (e): void => {
        if (Array.isArray(imagesArray)) {
          if (typeof imagesArray[0] === 'string' || imagesArray.length === 0) {
            (imagesArray as string[]).push(reader.result as string);
          } else {
            (imagesArray as ImageObject[]).push({ url: reader.result as string, id: '' });
          }
        }
      };
      reader.readAsDataURL(file);

      newImages.push({ filesize: file.size, file: file });
      totalFiles++;
    });

    const currentImages = formControl.value || [];
    formControl.patchValue([...currentImages, ...newImages]);
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  public onDrop(event: DragEvent, formControl: AbstractControl, imagesArray: string[] | ImageObject[]): void {
    event.preventDefault();
    this.onFileSelected(event, formControl, imagesArray);
  }

  public removeImage(index: number, formControl: AbstractControl, imagesArray: string[] | ImageObject[]): void {
    imagesArray.splice(index, 1);
    const currentImages = formControl.value;
    currentImages.splice(index, 1);
    formControl.patchValue(currentImages);
  }

  public deleteImage(
    index: number,
    attachmentId: string,
    formControl: AbstractControl,
    imagesArray: string[] | ImageObject[],
  ): void {
    const imageToDelete = formControl.get('imageToDelete').value;
    imageToDelete.push(attachmentId);

    formControl.patchValue({
      imageToDelete,
    });

    // Remove image from the images array
    imagesArray.splice(index, 1);

    // Ensure there's always an active image if any images remain
    if (imagesArray.length > 0) {
      const carouselInner = document.querySelector('.carousel-inner');
      if (carouselInner) {
        const items = carouselInner.querySelectorAll('.carousel-item');
        if (items.length > 0) {
          items[0].classList.add('active');
        }
      }
    }
  }

  public uploadImageToEntityUrl(url: string, file: File, id: number, basePath: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': file.type,
      'x-amz-acl': 'private',
    });

    return this.http.put(url, file, { headers, observe: 'response', responseType: 'text' }).pipe(
      catchError((error) => {
        console.error('Fehler beim Hochladen des Bildes:', error);
        return throwError(() => new Error('Upload fehlgeschlagen'));
      }),
    );
  }
}
