import { Component, EventEmitter, Inject, Input, isDevMode, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ClientInvitationModalComponent } from '../../shared/modals/client-invitation-modal/client-invitation-modal.component';

import { AdvisorsService, AUTH_SERVICE_TOKEN, AuthService, InsignService } from '../../shared/service';
import { AdvisorInfoUpdateService } from '../../shared/global-store';

import { Groups } from '../../shared/enum/groups.enum';
import { Roles } from '../../shared/enum/roles.enum';

import { Advisor, Company, InsignConnectionRequestModel } from '../../shared/model';

import WithSubscription from '../../shared/utils/WithSubscription';
import { datadogRum } from '@datadog/browser-rum';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  standalone: false,
})
export class HeaderComponent extends WithSubscription(Object) implements OnInit {
  @Input() firstLayer: boolean;
  @Input() title: string;

  @Output() open = new EventEmitter<any>();

  protected loading: boolean = true;
  protected innerWidth: number = innerWidth;
  // protected highestPriorityGroup: Groups | null = null;

  protected advisor: Advisor = new Advisor();
  protected company: Company = new Company();

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) protected readonly authService: AuthService,
    private advisorsService: AdvisorsService,
    public router: Router,
    private bsModalService: BsModalService,
    private advisorInfoUpdateService: AdvisorInfoUpdateService,
    private insignService: InsignService,
  ) {
    super();
  }

  public ngOnInit(): void {
    // this.highestPriorityGroup = this.getHighestPriorityGroup();
    this.loadUserProfile();

    this.advisorInfoUpdateService.getUpdatedAdvisor().subscribe({
      next: (): void => {
        this.loadUserProfile();
      },

      error: (error): void => {
        console.log(error);
      },
    });
  }

  // private getHighestPriorityGroup(): Groups | null {
  //   const GroupPriority = {
  //     [Groups.COMPANY_ADMIN]: 1,
  //     [Groups.ADVISOR]: 2,
  //     [Groups.BACKOFFICE]: 3,
  //     [Groups.TRAINEE]: 4,
  //   };
  //
  //   const userGroups: Groups[] = Object.values(Groups).filter((group: Groups) => this.authService.isInGroup(group));
  //   if (userGroups?.length === 0) {
  //     return null;
  //   }
  //
  //   return userGroups.reduce(
  //     (prev: Groups, curr: Groups): Groups => (GroupPriority[prev] < GroupPriority[curr] ? prev : curr),
  //   );
  // }

  private loadUserProfile(): void {
    this.advisorsService.getAdvisorProfile().subscribe({
      next: (data: Advisor): void => {
        this.advisor = data;
        this.company = this.advisor.company;
        this.loading = false;

        this.setDataDog();
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }

  protected openClientInvitationModal(): void {
    const clientInvitationModalRef = this.bsModalService.show(ClientInvitationModalComponent);
    clientInvitationModalRef.content.advisor = this.advisor;
  }

  protected back(): void {
    if (this.router.url.includes('vinhub')) {
      this.router.navigate(['/dashboard']);
    } else if (this.router.url.includes('bank-accounts-overview')) {
      this.router.navigate(['/bank-card-manager']);
    } else {
      window.history.back();
    }
  }

  private setDataDog(): void {
    datadogRum.setUser({
      id: this.advisor.uid,
      email: this.advisor.email,
      name: this.advisor.firstname + ' ' + this.advisor.lastname,
      role: 'admin',
      scope: 'read:message, write:files',
    });

    datadogRum.setUserProperty('company', this.company.name);
  }

  protected showUserSidebar(): void {
    this.open.emit(true);
  }

  // TODO: delete this test func start
  public connectINSIGN(): void {
    const request: InsignConnectionRequestModel = {
      clientId: '9f118e99-4a0b-41f0-80da-29a3c3801634',
      clientFirstName: 'Dariuszz',
      clientLastName: 'Borowski',
    };

    this.insignService.startInsignConnection(this.advisor.uid, request).subscribe({
      next: (data): void => {
        console.log(data);
      },
      error: (error): void => {
        console.log(error);
      },
    });
  }
  // TODO: delete this test func end

  protected readonly Groups = Groups;
  protected readonly Roles = Roles;
  protected readonly isDevMode = isDevMode;
}
