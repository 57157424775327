export class BankAccount {
  id: number;
  name: string;
  iban: string;
  bankIconUrl: string;
  balance: number;
  visible: boolean;
  isSharable: boolean;
  type: string;
}

export class AnalysisReportRequest {
  analysisId?: number;
  accountIds?: number[];

  balanceStatistics?: string;
  monthlyExpensesStatistics?: AnalysisReportDatePicker;
  categoryStatistics?: AnalysisReportDatePicker;
  monthlyTurnoverStatistics?: AnalysisReportDatePicker;
  incomeOutcomeStatistics?: AnalysisReportDatePicker;
  includeAccounts?: boolean;
}

export class AnalysisReportDatePicker {
  start: string;
  end: string;
}

export class AnalysisReport {
  accounts: BankAccount[];
  statistics: AnalysisReportStatistics;
}

export class AnalysisReportStatistics {
  balance: ReportStatisticsBalance;
  monthlyExpenses: ReportStatisticsMonthlyExpanses;
  category: ReportStatisticsCategory;
  monthlyTurnover: ReportStatisticsMonthlyTurnover;
  incomeOutcome: ReportStatisticsIncomeOutcome;
}

export class ReportStatisticsBalance {
  dataPoints: DataPoints[];
}

export class ReportStatisticsMonthlyExpanses {
  dataPoints: DataPoints[];
  averageExpenses: number;
}

export class FormattedMonthlyExpanses {
  dates: string[];
  numbers: number[];
}

export class ReportStatisticsCategory {
  dataPoints: CategoryDataPoints[];
}

export class ReportStatisticsMonthlyTurnover {
  dataPoints: MonthlyTurnoverDataPoints[];
}

export class CategoryDataPoints {
  // categoryId: number;
  name: string;
  amount: number;
  percent?: number;
}

export class ReportStatisticsIncomeOutcome {
  totalIncome: number;
  totalOutcome: number;
}

export class DataPoints {
  label: Date;
  value: number;
}

export class MonthlyTurnoverDataPoints {
  income: number;
  outcome: number;
  label: string;
}

export class BankAccountTransactionsRequest {
  account_id?: number[];
  analysisId?: number;

  mainCategoryGermanName?: string;
  categoryGroup?: string;

  from?: string;
  to?: string;

  finoOsContractId?: string;
  onlyContracts?: boolean;
  min_amount?: string;
  max_amount?: string;
  orderBy?: string;

  tagIds: number[];

  query?: string;
  counterpart_name?: string;

  hasAttachment?: boolean = null;

  descending?: boolean;
  page: number = 0;
  page_size: number = 50;
}

export class BankAccountTransactionsResponse {
  page: number;
  pageSize: number;
  total: number;
  data: BankAccountTransaction[];
  totalPages: number;
}

export class BankAccountTransaction {
  id: number;
  date: Date;
  amount: number;
  counterpartName: string;
  purpose: string;
  categoryName: string;
  categoryTag: string;
  categoryId: number;
  parentCategoryName: string;
  parentCategoryId: number;
  logoUrl: string;
  tagIds: number[];
  attachmentCount: number;
}

export class DepotTransactionRequest {
  account_id?: number[];
  from?: string;
  to?: string;
  min_amount?: string;
  max_amount?: string;
  orderBy?: string;
  query?: string;
  page: number = 0;
  page_size: number = 50;
  transactionType: string = null;
}

export class DepotTransactionResponse {
  page: number;
  pageSize: number;
  total: number;
  data: DepotTransactionModel[];
  totalPages: number;
}

export class DepotTransactionModel {
  id: number;
  name: string;
  isin: string;
  quoteDate: string;
  quote: number;
  quoteCurrency: string;
  quoteType: string;
  quantityNominal: number;
  quantityNominalType: string;
  marketValue: number;
  marketValueCurrency: string;
  entryQuote: number;
  entryQuoteCurrency: string;
  profitOrLoss: number;
  percentage: number;
  wkn: string;
  logoUrl?: string;
}
