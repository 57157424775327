import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ClientDetails, PersonDocumentModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(private http: HttpClient) {}

  public saveOrEdit(client: ClientDetails): Observable<ClientDetails> {
    return this.http.post<ClientDetails>(`${environment.apiEndpoint}/b2b/person`, client);
  }

  public deletePerson(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/b2b/person/${id}`);
  }

  public getPersonDocuments(
    keycloakId: string,
    type: 'DRIVER_LICENSE' | 'USER_ID' | 'PASSPORT' | 'INSURANCE' | 'OTHER',
  ): Observable<PersonDocumentModel[]> {
    return this.http.get<PersonDocumentModel[]>(`${environment.apiEndpoint}/b2b/person/${keycloakId}/document/${type}`);
  }
}
