<header class="header-container">
  <div class="content">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          @if (!firstLayer && innerWidth < 650) {
            <button type="button" class="btn btn-header back-btn" (click)="back()">
              <i class="bi bi-arrow-left theme-color"></i>
            </button>
          }

          <div class="parent-truncate pr-15">
            <div class="child-truncate section-title">{{ title }}</div>
          </div>

          <!-- TODO: delete this is Dev mode test insign btn start -->
          @if (isDevMode()) {
            <button class="btn btn-action-secondary" (click)="connectINSIGN()">INSIGN TEST CONNECTION</button>
          }
          <!-- TODO: delete this is Dev mode test insign btn end -->

          <app-pool-synch-modal *appHasRole="[Roles.POOL_CONNECTION]"></app-pool-synch-modal>

          @if (!this.router.url.includes('vinhub')) {
            <app-broker-mandate-modal *appHasRole="[Roles.MANDATE]"></app-broker-mandate-modal>
          }

          @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {
            <button
              class="btn btn-lg btn-circle btn-no-bg btn-outline btn-noborder d-flex align-items-center justify-content-center mr-10"
              type="button"
              (click)="openClientInvitationModal()"
            >
              <i class="bi bi-person-plus text-18" title="Kunden einladen"></i>
            </button>
          }

          @if (loading) {
            <ngx-skeleton-loader
              appearance="circle"
              [theme]="{
                width: '30px',
                height: '30px',
                margin: '0'
              }"
            ></ngx-skeleton-loader>
          } @else {
            <button
              type="button"
              class="btn btn-lg btn-circle user-icon-button d-flex align-items-center justify-content-center"
              (click)="showUserSidebar()"
            >
              @if (!advisor?.profilePictureUrl) {
                <img class="user-icon" alt="avatar" src="https://static-assets.vinlivt.de/img/misc/fox.png" />
              } @else {
                <img class="user-icon" alt="avatar" [src]="advisor.profilePictureUrl" />
              }
            </button>
          }
        </div>
      </div>
    </div>
  </div>

  <!-- Desktop view -->
  <!--  <div-->
  <!--    class="header-content-navbar"-->
  <!--    [ngClass]="{ 'justify-content-between': router.url.includes('vinhub') && leaveVinhubBtn }"-->
  <!--  >-->
  <!--    @if (router?.url?.includes('vinhub') && leaveVinhubBtn) {-->
  <!--      <button class="btn btn-action-secondary" routerLink="/dashboard">-->
  <!--        <i class="bi bi-arrow-left mr-5"></i>-->
  <!--        Zurück-->
  <!--      </button>-->
  <!--    }-->

  <!--    <div class="navbar-desktop-btn-group" role="group">-->
  <!--      <app-broker-mandate-modal></app-broker-mandate-modal>-->

  <!--      @if (authService.isInGroup(Groups.ADVISOR) || authService.isInGroup(Groups.TRAINEE)) {-->
  <!--        <button-->
  <!--          class="btn btn-rounded btn-noborder btn-action-secondary d-flex align-items-center"-->
  <!--          type="button"-->
  <!--          id="invite-client-btn"-->
  <!--          style="min-width: 42px; height: 42px"-->
  <!--          (click)="openClientInvitationModal()"-->
  <!--        >-->
  <!--          <i class="bi bi-plus-lg"></i>-->
  <!--          <span class="d-none d-lg-block ml-2">Neukunde einladen</span>-->
  <!--        </button>-->
  <!--      }-->

  <!--      <button type="button" class="btn btn-user" (click)="showUserSidebar()">-->
  <!--        @if (loading) {-->
  <!--          <ngx-skeleton-loader-->
  <!--            appearance="circle"-->
  <!--            [theme]="{-->
  <!--              width: '30px',-->
  <!--              height: '30px',-->
  <!--              margin: '0'-->
  <!--            }"-->
  <!--          ></ngx-skeleton-loader>-->
  <!--        } @else {-->
  <!--          @if (!advisor?.profilePictureUrl) {-->
  <!--            <img-->
  <!--              class="img-avatar img-avatar32 user-img-icon"-->
  <!--              src="https://static-assets.vinlivt.de/img/misc/fox.png"-->
  <!--              alt="avatar"-->
  <!--            />-->
  <!--          } @else {-->
  <!--            <img class="img-avatar img-avatar32 user-img-icon" alt="avatar" [src]="advisor.profilePictureUrl" />-->
  <!--          }-->
  <!--        }-->

  <!--        <span class="d-none d-md-flex flex-md-column ml-10">-->
  <!--          <div class="text-left">{{ advisor.firstname }} {{ advisor.lastname }}</div>-->

  <!--          <div class="d-flex align-items-center">-->
  <!--            <i *appHasGroup="[Groups.COMPANY_ADMIN]" class="bi bi-star-fill mr-1" style="color: #ffc75f"></i>-->
  <!--            <i *appHasGroup="[Groups.ADVISOR]" class="bi bi-star-fill mr-1" style="color: var(&#45;&#45;purple-color)"></i>-->
  <!--            <i *appHasGroup="[Groups.BACKOFFICE]" class="bi bi-star-fill mr-1" style="color: var(&#45;&#45;blue-color)"></i>-->
  <!--            <i *appHasGroup="[Groups.TRAINEE]" class="bi bi-star-fill mr-1 text-black"></i>-->

  <!--            @switch (highestPriorityGroup) {-->
  <!--              @case (Groups.COMPANY_ADMIN) {-->
  <!--                <span class="font-w300 mr-1">Administrator</span>-->
  <!--              }-->
  <!--              @case (Groups.ADVISOR) {-->
  <!--                <span class="font-w300 mr-1">Berater</span>-->
  <!--              }-->
  <!--              @case (Groups.BACKOFFICE) {-->
  <!--                <span class="font-w300 mr-1">Backoffice</span>-->
  <!--              }-->
  <!--              @case (Groups.TRAINEE) {-->
  <!--                <span class="font-w300">Tippgeber</span>-->
  <!--              }-->
  <!--            }-->
  <!--          </div>-->
  <!--        </span>-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->
</header>
